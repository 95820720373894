import { PlainBtn } from '@/Buttons/PlainBtn';
import { SecondaryBtn } from '@/Buttons/SecondaryBtn';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useRef } from 'react';
import { DesktopSubNav } from './DesktopSubNav';
import LanguagePicker from './LanguagePicker';
import { MobNav } from './MobNav';
import { navLinks } from './NavLinks';
import { flexSpace, px } from './styles/classes';

const Header = styled.header<{ scrolled: boolean }>`
    ${px};
    ${flexSpace};
    padding-top: 16px;
    padding-bottom: 16px;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 90;
    background-color: ${({ theme }) => theme.colors.white};
    position: sticky;
    top: 0;
    width: 100%;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            border-bottom: 2px solid ${theme.colors.gray3};
        `};

    @media (min-width: 1080px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    @media (max-width: 1500px) {
        .language-picker-desktop {
            display: none;
        }
    }
`;

const StyledLink = styled(Link)`
    transition: 0.3s opacity ease-in-out;

    :hover,
    :focus {
        opacity: 0.7;
    }

    @media (max-width: 1149px) {
        > img {
            width: 172px;
            height: 32px;
        }
    }
`;

const DesktopNav = styled.nav`
    display: none;

    @media (min-width: 1080px) {
        ${flexSpace};
        max-width: 940px;
        gap: 32px;
    }

    @media (min-width: 1450px) {
        width: fit-content;
    }
`;

const hoverLink = css`
    :not(.nav-menu-button-active) {
        position: relative;

        ::before {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 25px;
            height: 3px;
            border-radius: 15px;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s ease-in-out;
        }

        :focus,
        :hover {
            ::before {
                transform: scaleX(1);
            }
        }
    }
`;

const activeLink = css`
    position: relative;

    ::before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 22px;
        height: 3px;
        border-radius: 15px;
    }

    @media (min-width: 1080px) {
        ::before {
            right: 19px;
        }
    }
`;

const appointmentBtn = css`
    display: none;

    @media (min-width: 1366px) {
        display: block;
        padding: 8px 16px;
        font-size: 0.75rem;
    }
`;

export const StyledMenuButton = styled(PlainBtn)`
    &[data-reach-menu-button] {
        ${hoverLink};
        margin-bottom: 56px;
        color: ${({ theme }) => theme.colors.white};
        text-transform: capitalize;
        padding: 0;
        background: none;

        :last-of-type {
            margin-bottom: 0;
        }

        ::before {
            background: ${({ theme }) => theme.colors.orange2};
        }

        ::after {
            content: '';
            height: 9px;
            width: 9px;
            display: inline-block;
            border-right: 2px solid ${({ theme }) => theme.colors.white};
            border-bottom: 2px solid ${({ theme }) => theme.colors.white};
            transform: rotate(-45deg) translateY(2px);
            margin-left: 14px;
            margin-bottom: 2px;
            transition: transform 0.3s ease-in-out;
        }

        &[aria-expanded='true'] {
            :after {
                transform: rotate(135deg);
            }
        }

        &.nav-menu-button-active {
            ${activeLink};

            ::before {
                background-color: ${({ theme }) => theme.colors.orange1};
                transition: background-color 0.2s ease-in;
            }

            :focus,
            :hover {
                ::before {
                    background-color: ${({ theme }) => theme.colors.orange2};
                }
            }
        }
    }

    @media (max-height: 800px) {
        &[data-reach-menu-button] {
            margin-bottom: 40px;

            :last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @media (min-width: 1080px) {
        &[data-reach-menu-button] {
            font-size: 0.875rem;
            line-height: 175.18%;
            color: ${({ theme }) => theme.colors.gray5};
            margin-bottom: 0;

            ::after {
                border-color: ${({ theme }) => theme.colors.gray5};
                transform: rotate(45deg) translateY(-1.5px);
                width: 8px;
                height: 8px;
                margin-left: 11px;
            }

            &[aria-expanded='true'] {
                :after {
                    transform: rotate(-135deg) translateY(-3px);
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &[data-reach-menu-button] {
            font-size: 0.85rem;
        }
    }
`;

//for future use
export const NavLink = styled(Link)`
    color: ${({ theme }) => theme.colors.white};
    text-transform: capitalize;
    padding: 0;
    margin-bottom: 32px;

    :first-of-type {
        margin-top: 32px;
    }

    :last-of-type {
        margin-bottom: 0;
    }

    :hover,
    :focus {
        color: ${({ theme }) => theme.colors.purple3};
    }

    &.nav-link-current-page {
        ${activeLink};

        ::before {
            right: 0;
            background-color: ${({ theme }) => theme.colors.orange1};
        }
    }

    @media (max-height: 800px) {
        margin-bottom: 24px;

        :first-of-type {
            margin-top: 24px;
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1080px) {
        font-size: 0.875rem;
        line-height: 175.18%;
        margin-bottom: 0;
    }

    @media (min-width: 1600px) {
        font-size: 1rem;
    }
`;

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const Nav = () => {
    const { category } = useLocationData();

    const observeRef = useRef<HTMLElement | null>(null);

    const entry = useIntersectionObserver(observeRef);

    return (
        <Fragment>
            <Header scrolled={entry?.isIntersecting == false}>
                <StyledLink to="/">
                    <StaticImage
                        src="../../../assets/images/layout/logo.png"
                        alt="nuttall smiles - kent nuttall, dmd"
                        width={200}
                        height={36}
                        loading="eager"
                    />
                </StyledLink>
                <SkipNavLink />
                <MobNav category={category} />

                <DesktopNav>
                    {navLinks.map((link, i) =>
                        link.link === 'submenu' ? (
                            <DesktopSubNav text={link.text} category={category} key={i} navId={i} />
                        ) : (
                            <NavLink to={link.link} activeClassName="nav-link-current-page">
                                {link.text}
                            </NavLink>
                        )
                    )}
                </DesktopNav>
                <SecondaryBtn as="a" href="#appointment" css={appointmentBtn}>
                    Request an Appointment
                </SecondaryBtn>
                <LanguagePicker className="language-picker-desktop" />
            </Header>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
